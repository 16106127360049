import { Component } from "@angular/core";
import { ModalController, NavController } from "@ionic/angular";
import { Router } from "@angular/router";
import { AuthService } from "src/services/auth.service";
import { UtilityService } from "src/services/utility.service";
// import { FCMService } from "src/services/fcm.service";
import { AngularFirestore } from "@angular/fire/firestore";
import * as firebase from 'firebase';

import { FCM } from "cordova-plugin-fcm-with-dependecy-updated/ionic";

@Component({
  selector: "app-sign-in",
  templateUrl: "./sign-in.page.html",
  styleUrls: ["./sign-in.page.scss"],
})
export class SignInPage {
  windowRef:any;
  prefix:any;
    verifCode:any;
  phoneNumber = "";
  otp = "";
  verificationId: any;
  isOTPRequested = false;
  enableResend = false;
  remainingSecond = 60;
  interval: any;
  params: any;
  isVerificationInprogress = false;

  constructor(
    private navCtrl: NavController,
    private modalController: ModalController,
    private authService: AuthService,
    private utils: UtilityService,
    private utilityService: UtilityService,
    private route: Router,

    private afs: AngularFirestore
  ) {}

  ionViewWillEnter(){
    this.windowRef= this.authService.windowRef;
    this.windowRef.recaptchaVerifier= new firebase.auth.RecaptchaVerifier('recaptcha-container',{'size' : 'invisible'});
  }

  home() {
    this.navCtrl.navigateRoot(["./home"]);
  }

  sign_up() {
    this.route.navigate(["./sign-up"]);
  }

  dismiss() {
    this.modalController.dismiss();
  }

  sign_in() {
    if (this.interval !== undefined) {
      clearInterval(this.interval);
    }
    if (
      this.phoneNumber === undefined ||
      this.phoneNumber === null ||
      this.phoneNumber === ""
    ) {
      this.utils.showToast("Phone number is required");
    } else {
      this.sendLoginCode().then(
        (res) => {
          this.utils.showToast("OTP request is sent");
          localStorage.setItem("phonenumber", this.phoneNumber);
          this.utils.phone = this.phoneNumber; 
          this.isOTPRequested = true;
          this.enableResend = false;
          this.remainingSecond = 60;
        },
        (err) => {
          alert(err);
        }
      );
      this.interval = setInterval(() => {
        this.remainingSecond = this.remainingSecond - 1;
        if (this.remainingSecond === 0) {
          this.enableResend = true;
          clearInterval(this.interval);
        }
      }, 1000);
    }
  }

  sendLoginCode(){
    //Make sure phone number in e164 format
       const num=this.prefix.toString()+this.phoneNumber.toString();
       const appVerifier=this.windowRef.recaptchaVerifier;
      return firebase.auth().signInWithPhoneNumber(num,appVerifier)
       .then(result=>{
       this.windowRef.confirmationResult=result;
       console.log('otp sent')
       }).catch(err=>console.log('err1',err))
    }

  confirm_otp() {
    if (this.verifCode === undefined || this.verifCode === null || this.verifCode === "") {
      this.utils.showToast("OTP is required");
    } else {
      this.submitVerif();
    }
  }

  submitVerif(){
    return this.windowRef.confirmationResult.confirm(this.verifCode).then((result) => {
     localStorage.setItem('isLoggedIn', 'true');
     localStorage.setItem('uid', result.user.uid);
     this.utils.isLoggedIn = true;
     this.authService.getUserDetails(result.user.uid).then((res) => {
         this.isVerificationInprogress = true;
         // this.checkUser(result.user.uid);
         if (res.data() === undefined) { 
           this.route.navigate(["./sign-up"]); 
         } else {       
           localStorage.setItem("role", res.data()['role']);
           localStorage.setItem("name", res.data()['fullname']);
           this.utils.isLoggedIn = true; 
           localStorage.setItem("isloggedin", "true");
          //  console.log(res.data());
           
          //  localStorage.setItem("phonenumber", res.data()['phoneNumber']);
           this.routeUser();
         }
       },
       (err) => {
         alert(err);
         this.route.navigate(["./sign-in"]);
       }
     );
   }
    );
    
   }

  checkUser(info) {
    localStorage.setItem("uid", info["uid"]);
    FCM.getToken().then((token) => {
      console.log(token);
      if (
        localStorage.getItem("uid") !== null &&
        localStorage.getItem("uid") !== undefined
      ) {
        this.saveTokenToFirestore(token, localStorage.getItem("uid"));
      }
    });
    this.authService.checkUser(info.uid).subscribe((res) => {
      this.isOTPRequested = false;
      this.otp = "";
      this.verificationId = "";
      this.enableResend = false;
      this.remainingSecond = 60;
      if (res === undefined) {
        this.route.navigate(["./sign-up"]);
      } else {
        localStorage.setItem("isloggedin", "true");
        localStorage.setItem("phonenumber", info["phoneNumber"]);
        this.routeUser();
      }
    });
  }

  routeUser() {
    if (
      localStorage.getItem("currentpage") === null ||
      localStorage.getItem("currentpage") === undefined ||
      localStorage.getItem("currentpage") === "home"
    ) {
      this.route.navigate(["home"]);
    } else if (localStorage.getItem("currentpage") === "cart") {
      this.route.navigate(["cart"]);
    } else if (localStorage.getItem("currentpage") === "shop") {
      const navigationExtras = {
        queryParams: {
          shopId: localStorage.getItem("storeid"),
        },
      };
      this.route.navigate(["shop"], navigationExtras);
    } else if (localStorage.getItem("currentpage") === "products") {
      const navigationExtras = {
        queryParams: {
          shopId: localStorage.getItem("storeid"),
          categoryId: localStorage.getItem("categoryid"),
          categoryName: localStorage.getItem("categoryname"),
        },
      };
      this.route.navigate(["products"], navigationExtras);
    } else if (localStorage.getItem("currentpage") === "select-address") {
      this.route.navigate(["select-address"]);
    } else if (localStorage.getItem("currentpage") === "offers") {
      this.route.navigate(["offers"]);
    } else {
      this.route.navigate(["home"]);
    }
  }
  saveTokenToFirestore(token, uid) {
    if (!token) return;
    const devicesRef = this.afs.collection("devices");
    const docData = {
      token,
      userid: uid,
    };
    return devicesRef.doc(uid).set(docData);
  }
}
